import ModelForm from '@/components/ModelForm/ModelForm.vue'

export default {
  components: {
    ModelForm
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Models',
          disabled: false,
          to: { name: 'list-models' },
          exact: true
        },
        { text: 'Create' }
      ]
    }
  }
}
